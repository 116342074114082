.file {
  width: 555px;
  height: 82px;
  padding: 0 16px 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px;

  &__description {
    flex: 1;
    padding-left: 25px;
  }

  &__name {
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__size {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
  }

  &__progress-line {
    width: 42.5%;
    margin-top: 3px;
  }

  &__remove-icon {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 5px;

    &:hover {
      background-color: rgba(16, 56, 79, 0.06);
    }
  }
}
