.pop-up {
  // position: absolute;
  //  z-index: 10;
  // top: 35px;
  right: 0;
  width: 100%;
  border-radius: 6px;
  gap: 1px;
  background: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.--active {
  display: flex;
}

.--inactive {
  display: none;
}
