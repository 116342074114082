#container {
  width: 492px;
  height: 376px;

  .gigya-composite-control.gigya-composite-control-label {
    display: none;
  }
  .gigya-composite-control.gigya-composite-control-header.mandatory {
    display: none;
  }

  h1 {
    display: none !important;
  }

  [data-switch-screen="bayer-register-nosc"] {
    display: none;
  }

  .gigya-layout-cell {
    position: relative;
    .gigya-composite-control.gigya-composite-control-link {
      position: absolute;
      left: 0;
    }
  }

  .gigya-input-submit {
    color: white !important;
    background-color: rgba(0, 145, 223, 1) !important;
  }

  .gigya-composite-control.gigya-composite-control-link {
    color: rgba(0, 145, 223, 1) !important;
    font-size: 14px !important;
  }

  .gigya-input-text {
    height: 56px !important;
    border-radius: 5px !important;
  }
}

.login-logo {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 8px;
  }

  &__text {
    // font-family: 'Helvetica Neue';
    font-size: 14px;
    line-height: 20px;
    color: rgba(16, 56, 79, 1);
  }
}

.login-header {
  margin-top: 12px;
  margin-bottom: -35px;
  font-size: 24px;
  color: rgba(16, 56, 79, 1);
  font-size: 24px;
  color: rgb(16, 56, 79);
  font-weight: 400;
}

.login-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.login-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  .login-wrapper {
    margin-left: 95px;
  }

  .login-image {
    width: 58%;
    max-width: 830px;
  }

  @media (max-width: 1000px) {
    .login-wrapper {
      margin: auto;
    }
    .login-image {
      display: none;
    }
  }
}
