.container {
  width: 100%;
}
.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 603px;
  height: 146px;
  background-color: rgba(250, 250, 252, 1);
  border: 1px dashed rgba(225, 227, 235, 1);
  border-radius: 5px;
}

.dropzone-content {
  &__icon {
    display: flex;
    justify-content: center;
    margin-top: 6px
  }
  &__s{
    margin-top: 15px;
    font-size: 16px;
    line-height: 24px;
    color: rgba(16, 56, 79, 1);
  }
  &__link {
    color: rgba(0, 145, 223, 1);
    text-decoration: underline;
  }
  &__specification {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    color: rgba(81, 110, 127, 1);
  }
}
