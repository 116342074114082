.footer {
    background-color: #ffffff;
    padding: 20px 30px;
    border-top: 1px solid #ddd;
    font-size: 14px;
    color: #555;
    text-align: center;
  }
  
  .footer-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-wrapper p {
    margin: 0;
    font-size: 15px;
    color: black;
  }
  
  .footer-navigation {}
  
  .footer-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
  
  .footer-menu li {
    display: inline;
  }
  
  .footer-menu a {
    color: rgb(0, 145, 223);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-menu a:hover {
    text-decoration: underline;
    color: #005bb5;
  }
  
  @media (max-width: 768px) {
    .footer-wrapper {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-navigation {
      margin-top: 15px;
    }
  
    .footer-menu {
      gap: 10px;
    }
  }
  