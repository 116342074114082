.load-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  margin: auto;
  margin-top: 115px;

  &__title {
    width: 100%;
    margin: 0;
    margin-bottom: 10.5px;
    font-size: 30px;
    line-height: 36px;
    font-weight: 400;
  }

  &__description {
    margin-bottom: 23px;
    font-size: 16px;
    line-height: 24px;
    color: rgba(81, 110, 127, 1);
  }

  .attached-files {
    margin-top: 6px;
  }
}
.loaded-files {
  width: 70%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: 10px;
    margin-top: 10px;
  }
}

.submit {
  margin-top: 25px;
  margin-bottom: 25px;
}

.saved-list {
  display: flex;
  flex-direction: column;
  > div {
    margin-top: 10px;
  }
}
