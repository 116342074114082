$font-style: normal;
$text-align: start;
$margin-bottom-main: 36px;
$font-size-main: 16px;
$font-color-main: #10384f;
$font-color-alternative: #0d2a40;
$font-weight-main: 500;
$font-weight-light: 300;

.consent-info-page {
  max-width: 976px;
  margin: 0 auto;
  padding-bottom: 20px;


h1,
h2,
h3,
h4,
p,
a {
  text-align: $text-align;
  font-style: $font-style;
}

h1 {
  font-size: 45px;
  color: $font-color-main;
  font-weight: 200;
  margin-bottom: 50px;
}

h2,
h3 {
  color: $font-color-main;
  font-weight: $font-weight-light;
}

h2 {
  font-size: 36px;
  letter-spacing: 0.9px;
  margin-top: 42px;
  margin-bottom: $margin-bottom-main;
  line-height: 41px;
}

h3 {
  font-size: $font-size-main;
  letter-spacing: 0.5px;
  line-height: 25px;
  margin-bottom: 0;
}

h4 {
  color: $font-color-main;
  font-size: $font-size-main;
  font-weight: $font-weight-main;
  letter-spacing: 0.56px;
  line-height: 1.56;
  margin-bottom: 0;
}

p {
  color: $font-color-alternative;
  font-weight: $font-weight-light;
  font-size: $font-size-main;
  line-height: 1.75;
  letter-spacing: 0.5px;
  margin-top: 5px;
  margin-bottom: $margin-bottom-main;
}

ul {
  text-align: $text-align;
  padding-left: 20px;

  li {
    margin-left: 5px;
    font-size: $font-size-main;
    font-weight: $font-weight-light;
    color: $font-color-alternative;
    letter-spacing: 0.5px;
    line-height: 1.75;
  }
}

.align-center {
  text-align: center;
}

a {
  text-decoration: none;
  color: #0075a6;
  font-weight: $font-weight-main;

  &:hover {
    text-decoration: underline;
  }
}
}