header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  border-top: 1px solid rgba(16, 56, 79, 0.12);
  border-bottom: 1px solid rgba(16, 56, 79, 0.12);
}

.logo,
.user-profile {
  display: flex;
  align-items: center;

  &__description {
    margin-left: 10px;
  }
}

.logo {
  &__icon {
    width: 40px;
    height: 40px;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
  }
}
.user-profile {
  position: relative;
  border-radius: 12px;
  padding: 11px;
  cursor: default;

  &__icon {
    width: 26px;
    height: 26px;
  }

  &__description {
    font-size: 16px;
    font-weight: 700;
  }

  &.--active {
    background-color: rgba(16, 56, 79, 0.06);
  }

  &:hover {
    background-color: rgba(16, 56, 79, 0.06);
  }
}

.user-profile__pop-up.pop-up {
  position: absolute;
  top: 55px;
  width: 100%;
}

.pop-up__sign-out {
  &:hover {
    background-color: rgba(16, 56, 79, 0.06);
  }
  width: 100%;
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    padding-left: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  > img {
    padding-right: 16px;
    width: 16px;
    height: 16px;
  }
}
