.result-window {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__status {
    margin-top: 18px;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    font-weight: 500;
    color: rgba(16, 56, 79, 1);
  }

  &__description {
    margin-bottom: 37px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(81, 110, 127, 1);
  }
}

.result-window-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
}
